/* Info Carousel Block JS File */
$(document).ready(function() {
	if ($(".info-carousel-wrapper").length) {
		$(".info-carousel-wrapper .section-info-carousel .info-slick").slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: true,
			autoplaySpeed: 2000,
			speed: 1000,
			mobileFirst: true,
			pauseOnDotsHover: false,
			pauseOnFocus: false,
			pauseOnHover: true,
			arrows: false,
			dots: true,
			fade: true,
			rows: 0,
			appendDots: '.info-carousel-navigation',
			customPaging: function(slider, i) {
				return '<button><span>' + ('0' + (i + 1)).slice(-2) + '</span></button>';
			}
		});
	}
});
