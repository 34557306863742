/* Tabbed Content Block JS File */
import tab from 'bootstrap';
$(document).ready(function() {
	if ($('.tabbed-content-wrapper').length > 0) {

	// 	if ($(".tabbed-content-wrapper").length > 0) {
	// 		$('.tabbed-content-wrapper .accordion').collapse().on('hide.bs.collapse hidden.bs.collapse show.bs.collapse shown.bs.collapse', function(e) {
	// 			TweenMax.to(window, 0.5, { scrollTo: { y: scrollSnap.activeSnap, autoKill: false}, ease: Power4.easeOut });
	// 		});
	// 	}
	// 	$('[data-toggle="collapse"]').on('click', function(e) {
	// 		const isOpen = $($(this).attr('href')).hasClass('show');
	// 		if (isOpen) {
	// 			e.preventDefault();
	// 			e.stopPropagation();
	// 		}
	// 	});
	// 	if ($(".card-header").length) {
	// 		const cardheader = $(".card-header");
	// 		cardheader.on('click', function() {
	// 			$(".tabbed-content-wrapper .section-body .section-accordion .section-backgrounds-wrapper .section-background[data-id=" + $(this).attr('data-id') + "]").addClass('active');
	// 			$(".tabbed-content-wrapper .section-body .section-accordion .section-backgrounds-wrapper .section-background[data-id!=" + $(this).attr('data-id') + "]").removeClass('active');
	// 		});
	// 	}


	// 	$('.tabbed-content-tab').tab();
	// 	$('.nav-tabs a').on('click', function (e) {
	// 		e.preventDefault()
	// 		$(this).tab('show')
	// 	});
	// 	if ($(".nav-link").length) {
	// 		const navlink = $(".nav-link");
	// 		navlink.on('click', function() {
	// 			$(".tabbed-content-wrapper .section-body .section-tabs .tabbed-content-tab .nav .section-images-wrapper .section-image[data-id=" + $(this).attr('data-id') + "]").addClass('active');
	// 			$(".tabbed-content-wrapper .section-body .section-tabs .tabbed-content-tab .nav .section-images-wrapper .section-image[data-id!=" + $(this).attr('data-id') + "]").removeClass('active');
	// 		});
	// 	}

	}

});

$(window).on('resize rotate load', function() {

	if ($('.tabbed-content-wrapper').length > 0) {

		if ($(window).width() < 768) {

			$('.tabbed-content-wrapper').removeClass('is-tabs');
			$('.tabbed-content-wrapper').addClass('is-accordion');

			$('.tabbed-content-wrapper .tab-content .tab-pane').each(function() {
				const element = $(this);
				const elementID = element.attr('data-id');
				element.appendTo("#" + elementID);
			});

			let maxHeight = 0;
			let match;
			match = $('.tabbed-content-wrapper .nav-link .tab-pane');
			match.each(function() {
				if ($(this).height() > maxHeight) { maxHeight = $(this).height() + 200; }
			});

			$(".tabbed-content-wrapper .nav-link .tab-pane").css({ maxHeight: 0 });
			$(".tabbed-content-wrapper .nav-link .tab-pane.show.active").css({ maxHeight: maxHeight });

			$(document).on('click', '.tabbed-content-wrapper .nav-item', function(e) {
				if ($(window).width() < 768) {
					e.preventDefault();
					const $this = $(this);
					$(".tab-pane").removeClass('active show').css({ maxHeight: 0 });
					$(".tab-pane", $this).addClass('active show').css({ maxHeight: maxHeight });
					setTimeout(function() {
						$('html, body').animate({
							scrollTop: $(".tab-pane", $this).offset().top - 200
						}, 200);
					}, 1000);
				}
			});

		} else {

			if ($('.tabbed-content-wrapper').hasClass('is-accordion')) {
				$('.tabbed-content-wrapper .nav-item .tab-pane').each(function() {
					$(this).css({ maxHeight: 'none' }).appendTo(".tabbed-content-wrapper #nav-tab-content");
				});
			}

			$('.tabbed-content-wrapper').removeClass('is-accordion');
			$('.tabbed-content-wrapper').addClass('is-tabs');

		}

		if ($(".nav-link").length) {
			const navlink = $(".nav-link");
			navlink.on('click', function() {
				$(".tabbed-content-wrapper .section-body .nav-tabs .section-images-wrapper .section-image[data-id=" + $(this).attr('data-id') + "]").addClass('active');
				$(".tabbed-content-wrapper .section-body .nav-tabs .section-images-wrapper .section-image[data-id!=" + $(this).attr('data-id') + "]").removeClass('active');
			});
		}
	}
});
