/* Parent Gallery Block JS File */
import '../../../src/js/_before-After';

$(document).ready(function() {
	if ($('.parent-gallery-wrapper').length > 0) {
		$(document).on('click', '.parent-gallery-wrapper .parent-gallery-trigger', function(e) {
			e.preventDefault();
			const parent = $(this).attr('data-parent');
			const slug = $(this).attr('data-slug');
			const this_ID = '.parent-gallery-wrapper #' + $(this).attr('id');
			const url = '/' + parent + '/' + slug + '/';
			$(".parent-gallery-wrapper .parent-gallery-trigger").removeClass('active');
			$(this_ID).addClass('active');
			$(".parent-gallery-wrapper .tab-pane").removeClass('active show');
			$(".parent-gallery-wrapper .tab-pane#" + slug).tab('show');
			if (window.history.replaceState) {
				window.history.pushState({}, null, url);
			}
			sidebar();

			if (!$('.tab-wrapper').hasClass('active')) {
				$('.tab-wrapper').addClass('change');
				setTimeout( function() {
					$('.tab-wrapper').removeClass('change');
					$('.tab-wrapper').addClass('active');
					const halfbackgroundBottom = $('.parent-gallery-wrapper #section-background-bottom').height() / 2;
					$('#section-background-top').css('height', 'calc(100% + ' + halfbackgroundBottom + 'px)');
				}, 500);
			}
			else {
				const halfbackgroundBottom = $('.parent-gallery-wrapper #section-background-bottom').height() / 2;
				$('#section-background-top').css('height', 'calc(100% + ' + halfbackgroundBottom + 'px)');
			}

			// $('html, body').animate({
				// scrollTop: $('.content-with-sidebar-1').offset().top - 50
			// }, 500);

		});


		$(window).on('load rotate resize', function() {
			const halfbackgroundBottom = $('.parent-gallery-wrapper #section-background-bottom').height() / 2;
			$('.parent-gallery-wrapper #section-background-top').css('height', 'calc(100% + ' + halfbackgroundBottom + 'px)');
			$('.parent-gallery-wrapper #section-background-left-right').addClass('active');
		});


		$(".parent-gallery-wrapper .content-wrapper .tab-wrapper .tab-content .before-after-wrapper").each(function(index) {
			const i = index + 1;
			$(this).beforeAfter();
		});
	}
});

