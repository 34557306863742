/* Homepage Boxes Block JS File */
$(document).ready(function() {
	if ($(".homepage-boxes-wrapper").length) {

		$(window).on('scroll', function() {
			if ($(window).width() >= 992) {
				const top_of_hbw = $(".homepage-boxes-wrapper").offset().top;
				const height_of_hbw =  $(".homepage-boxes-wrapper").outerHeight();
				if ($(window).scrollTop() > (top_of_hbw - (height_of_hbw / 5)) && // - (height_of_hbw / 2)
					$(window).scrollTop() < (top_of_hbw + height_of_hbw)) {
					$(".homepage-boxes-wrapper").addClass('active');
					if (!$('.info-boxes').hasClass('open')) {
						$('.info-boxes').addClass('open');
					}
				}
				else { $(".homepage-boxes-wrapper").removeClass('active'); }
			}
		});

		$(window).on('load rotate resize', function() {
			const topPart = $('.homepage-boxes-wrapper .top-part');
			if ($(window).width() < 992) {
				matchHeight(topPart);
				if ($('.section-body').hasClass('empty')) {
					$('.section-info').each(function() {
						$(this).find('.section-box-content').appendTo($(this).attr('data-link'));
						$(this).find('.section-box-button').appendTo($(this).attr('data-link'));
					});
					$('.section-info').addClass('empty'); $('.section-body').removeClass('empty');
				}
			}
			else {
				topPart.height('auto');
				if ($('.section-info').hasClass('empty')) {
					$('.section-body').each(function() {
						$(this).find('.section-box-content').appendTo($(this).attr('data-link'));
						$(this).find('.section-box-button').appendTo($(this).attr('data-link'));
					});
					$('.section-body').addClass('empty'); $('.section-info').removeClass('empty');
				}
			}
		});

		// const boxInfoSlick = $(".section-info-slick").slick({
		// 	slidesToShow: 1,
		// 	slidesToScroll: 1,
		// 	initialSlide: 0,
		// 	focusOnSelect: true,
		// 	autoplay: false,
		// 	autoPlaySpeed: 2000,
		// 	speed: 1000,
		// 	mobileFirst: true,
		// 	adaptiveHeight: true,
		// 	arrows: false,
		// 	dots: false,
		// 	infinite: true,
		// 	fade: true,
		// 	asNavFor: '.section-nav-slick',
		// }).on('beforeChange', function(event, slick, currentSlide, nextSlide) {
		// 	$('.slick-slide', $(this)).removeClass('slick-fix');
		// 	$('.slick-active.slick-cloned', $(this)).addClass('slick-fix');
		// });

		// const boxNavSlick = $(".section-nav-slick").slick({
		// 	slidesToShow: $('.section-nav-slick').attr('data-number'),
		// 	slidesToScroll: 1,
		// 	focusOnSelect: true,
		// 	autoplay: false,
		// 	autoPlaySpeed: 2000,
		// 	speed: 1000,
		// 	vertical: true,
		// 	asNavFor: '.section-info-slick',
		// });

		$('.section-nav').on('click', function() {
			if ($(window).width() > 991) {
				$('.section-info').removeClass('active');
				$('.section-nav').removeClass('active');
				$(this).addClass('active');
				$($(this).attr('data-link')).addClass('active');
			}
		});
	}
});
