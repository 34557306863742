/* Gallery Grid Block JS File */
import '../../../src/js/_before-After';
import select2 from 'select2';

$(document).ready(function() {

	if ($(".gallery-grid-block").length) {

		$(".gallery-grid-wrapper #gallery-grid-box .section-gallery-grid .section-galleries #gallery-grid-load .grid-item-wrapper .grid-item-image .before-after-wrapper").each(function(index) {
			const i = index + 1;
			$(this).beforeAfter();
		});

		$(".filter-value").select2();

		function galleryGridFormData() {
			let formData = {};
			$.each($('#gallery-grid-form').serializeArray(), function() {
				formData[this.name] = this.value;
			});
			return formData;
		}

		function galleryGridCreateUrl() {
			let formData = galleryGridFormData();
			const category = typeof formData.category !== "undefined" && formData.category !== 'all' ? formData.category + '/' : '';
			return baseUrl + category;
		}

		function galleryGridPushUrl() {
			if (window.history.replaceState) {
				let formData = galleryGridFormData();
				window.history.pushState({
					formData: formData,
				}, null, galleryGridCreateUrl());
			}
		}

		function galleryGridLoad() {
			// postGridPushUrl();
			$("#gallery-grid-loader").addClass('active');
			$("#gallery-grid-load").load(coreData.ajaxurl, {
				action: 'load_gallery_grid',
				formData: galleryGridFormData(),
				galleryGridData: $("#gallery-grid-load").attr('data-pgd'),
			}, function(response) {
				$("#gallery-grid-loader").removeClass('active');
				lazyLoadInstance.update();
				$(window).trigger('resize');
				$(".gallery-grid-wrapper #gallery-grid-box .section-gallery-grid .section-galleries #gallery-grid-load .grid-item-wrapper .grid-item-image .before-after-wrapper").each(function(index) {
					const i = index + 1;
					$(this).beforeAfter();
				});
			});
		}

		$("#gallery-grid-form .filter-value").on('select2:select', function() {
			galleryGridLoad();
		});

	}
});
