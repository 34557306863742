/* Content and Image Block JS File */
$(document).ready(function() {
	if ($('.content-and-image-wrapper').length) {
		$(window).on('load rotate resize', function() {
			const colHeight = $('.content-and-image-wrapper .section-inner .col-height');
			if ($(window).width() >= 992) { matchHeight(colHeight); }
			else { colHeight.height('auto'); }
		});
	}
});
