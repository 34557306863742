/* Featured Gallery Block JS File */
import slick from 'slick-carousel';
import '../../../src/js/_before-After';

$(document).ready(function() {

	if ($(".featured-gallery-wrapper").length) {

		$(window).on('scroll', function() {
			if ($(window).width() >= 992) {
				const top_of_fgw = $(".featured-gallery-wrapper").offset().top;
				const height_of_fgw =  $(".featured-gallery-wrapper").outerHeight();
				if ($(window).scrollTop() > (top_of_fgw - (height_of_fgw / 10)) && // - (height_of_fgw / 2)
					$(window).scrollTop() < (top_of_fgw + height_of_fgw)) {
					$(".featured-gallery-wrapper").addClass('active');
					if (!$('.info-boxes').hasClass('open')) {
						$('.info-boxes').addClass('open');
					}
				}
				else { $(".featured-gallery-wrapper").removeClass('active'); }
			}
		});

		$(window).on('load rotate resize', function() {
			const topPart = $('.featured-gallery-wrapper .top-part');
			if ($(window).width() < 992) { matchHeight(topPart); }
			else { topPart.height('auto'); }

			// matchWidth('.featured-gallery-wrapper .section-behind #gallery-wrapper .gallery-slick-wrapper .featured-gallery-slick .section-gallery-wrapper .featured-gallery .before-after-wrapper .background');
		});

		$('.featured-gallery-wrapper .before-after-wrapper').beforeAfter();

		const gallerySlick = $(".featured-gallery-slick").slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: true,
			autoplaySpeed: 3000,
			speed: 1000,
			mobileFirst: true,
			pauseOnDotsHover: false,
			pauseOnFocus: false,
			pauseOnHover: true,
			arrows: true,
			prevArrow: '<button type="button" class="slick-prev"><i class="fal fa-arrow-left"></i></button>',
			nextArrow: '<button type="button" class="slick-next"><i class="fal fa-arrow-right"></i></button>',
			dots: false,
			appendDots: '.featured-gallery-navigation',
			fade: true,
		});

	}

});


// window.matchWidth = function(element) {
// 	let maxWidth = 0;
// 	let match;
// 	match = $(element);
// 	match.width('auto');
// 	match.each(function() {
// 		if ($(this).width() > maxWidth) { maxWidth = $(this).width(); }
// 	});
// 	match.width(maxWidth);
// }
