import mmenu from 'mmenu-js';

const socialMedia = [];
if (Object.keys(coreData.socialMedia).length) {
	$.each(coreData.socialMedia, function(key, value) {
		socialMedia.push(value.html);
	});
}

$(document).ready(function() {
	const mobileMenu = $('#mobile-menu').mmenu({
		slidingSubmenus: true,
		extensions: [
			"fullscreen",
			"popup",
		],
		navbar: {
			title: ""
		},
		hooks: {
			"open:before": function() {
				$("body").addClass('nav-open');
				$("body").removeClass('nav-closed');
				$(".site-nav-hamburger").addClass('is-active');
			},
			"close:before": function() {
				$("body").removeClass('nav-open');
				$("body").addClass('nav-closed');
				$(".site-nav-hamburger").removeClass('is-active');
			}
		}
	}, {
		offCanvas: {
			page: {
				selector: '#page-wrapper'
			}
		}
	});

	const arrowLeft = $("#mobile-menu").attr('data-arrow-left');
	const arrowRight = $("#mobile-menu").attr('data-arrow-right');

	$("#mobile-menu .mm-navbar .mm-navbar__title").wrapInner('<span class="navbar-title-wrapper"></span>').prepend('<img src="' + arrowLeft + '" class="navigation-arrow">');
	$("#mobile-menu .mm-listitem__btn").prepend('<img src="' + arrowRight + '" class="navigation-arrow">');
	$("#mobile-menu .mm-listitem__text").wrapInner('<span class="navbar-title-wrapper"></span>');

	// Get the API
	const mmenuApi = mobileMenu.data("mmenu");
	$("body").on('click', '.menu-trigger', function() {
		if ($(".mobile-header").hasClass('mh-pinned')) {
			$('body').removeClass('mh-unpinned').addClass('mh-pinned');
		} else {
			$('body').removeClass('mh-pinned').addClass('mh-unpinned');
		}
		// const isDesktop = $('html').hasClass('desktop');
		// if (!isDesktop) {
			if ($('body').hasClass('nav-open')) {
				mmenuApi.close();
			} else {
				mmenuApi.open();
			}
		// }
	});

	$(window).on('load rotate resize', function() {
		if ($(window).width() >= 1200) {
			if ($('body').hasClass('nav-open')) {
				mmenuApi.close();
			}
			if ($('body').hasClass('information-bar-menu-open')) {
				toggleMenuBar();
				$('.toggle-information-bar').removeClass('active');
			}
		}
	});
});
