/* Contact Details Block JS File */
$(document).ready(function() {
	if ($('.contact-details-wrapper').length) {
		$('.contact-details-wrapper .section-right .section-form .gfield.request .ginput_container_select select.gfield_select').change( function() {
			if ($(this).val() == 'request') {
				$('.contact-details-wrapper .section-right .section-form').addClass('active');
				$('.contact-details-wrapper .section-right .section-button').addClass('active');
			}
			else {
				$('.contact-details-wrapper .section-right .section-form').removeClass('active');
				$('.contact-details-wrapper .section-right .section-button').removeClass('active');
			}
		});
	}
});
