/* Gravity Form JS File */
$(document).ready(function() {
	if ($('.widget-gravity-form-wrapper').length) {
		$('.widget-gravity-form-wrapper .section-form .gfield.request .ginput_container_select select.gfield_select').change( function() {
			if ($(this).val() == 'request') {
				$('.widget-gravity-form-wrapper .section-form').addClass('active');
				$('.widget-gravity-form-wrapper .section-button').addClass('active');
			}
			else {
				$('.widget-gravity-form-wrapper .section-form').removeClass('active');
				$('.widget-gravity-form-wrapper .section-button').removeClass('active');
			}
		});
	}
});
