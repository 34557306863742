/* Featured Gallery Small Block JS File */
import slick from 'slick-carousel';
import '../../../src/js/_before-After';

$(document).ready(function() {

	if ($(".featured-gallery-small-wrapper").length) {
		$('.featured-gallery-small-wrapper .before-after-wrapper').beforeAfter();
		const smallGallerySlick = $(".featured-gallery-small-slick").slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: true,
			autoplaySpeed: 3000,
			speed: 1000,
			mobileFirst: true,
			pauseOnDotsHover: false,
			pauseOnFocus: false,
			pauseOnHover: true,
			arrows: true,
			prevArrow: '<button type="button" class="slick-prev"><i class="fal fa-arrow-left"></i></button>',
			nextArrow: '<button type="button" class="slick-next"><i class="fal fa-arrow-right"></i></button>',
			dots: false,
			appendDots: '.featured-gallery-small-navigation',
			fade: true,
		});
	}
});
