/* Treatment Carousel Block JS File */
import slick from 'slick-carousel';

$(document).ready(function() {

	if ($(".treatment-carousel-wrapper .slick-treatment-image")) {

		const max = parseInt($('.treatment-carousel-navigation').attr('data-count'), 10);

		$(".treatment-carousel-wrapper .slick-treatment-image").on('init', function() {
		}).slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: true,
			autoplaySpeed: 5000,
			speed: 1000,
			mobileFirst: true,
			pauseOnDotsHover: true,
			pauseOnFocus: true,
			pauseOnHover: true,
			arrows: true,
			appendArrows: '.treatment-carousel-navigation',
			prevArrow: '<span><i class="far fa-arrow-left"></i></span><div class="pagination"> <span id="current">1</span> / <span id="max">'+ max +'</span>',
			nextArrow: '</div><span><i class="far fa-arrow-right"></i></span>',
			dots: false,
			centerMode: true,
			centerPadding: '0',
			focusOnSelect: true,
			rows: 0,
			fade: true,
			responsive: [
				{
					breakpoint: 767,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 2,
						fade: false,
						centerMode: true
					}
				},
				{
					breakpoint: 991,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 3,
						fade: false,
						centerMode: true
					}
				},
			]
		}).on('beforeChange', function(event, slick, currentSlide, nextSlide) {
			if ((nextSlide + 1) > max) {
				$('.treatment-carousel-navigation #current').text((nextSlide + 1) - max);
			} else {
				$('.treatment-carousel-navigation #current').text(nextSlide + 1);
			}
		});
	}

});
