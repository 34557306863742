/* Child Post Menu Block JS File */
$(document).ready(function() {
	if ($(".child-post-menu-wrapper").length) {
		// $(window).on('load rotate resize', function() {
		// 	const postContent = $('.child-post-menu-wrapper .child-post-wrapper .child-post-body .child-post-body-wrapper .child-post-content-wrapper');
		// 	if ($(window).width() >= 768) { matchHeight(postContent); }
		// 	else { postContent.height('auto'); }
		// });
	}
});
