/* Map And Contact Info Block JS File */
import slick from 'slick-carousel';
$(document).ready(function() {

	if ($('.map-and-contact-info-block').length > 0) {
		const mapCarouselSlick = $(".map-carousel-slick").slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			initialSlide: 0,
			focusOnSelect: true,
			autoplay: true,
			autoPlaySpeed: 500,
			speed: 500,
			mobileFirst: true,
			adaptiveHeight: true,
			arrows: false,
			dots: true,
			infinite: true,
			fade: true,
			asNavFor: '.map-nav-slick',
			appendDots: '.map-carousel-dots',
		}).on('beforeChange', function(event, slick, currentSlide, nextSlide) {
			$('.slick-slide', $(this)).removeClass('slick-fix');
			$('.slick-active.slick-cloned', $(this)).addClass('slick-fix');
		});

		const mapNavSlick = $(".map-nav-slick").slick({
			slidesToShow: $('.map-nav-slick').attr('data-number'),
			slidesToScroll: 1,
			focusOnSelect: true,
			autoplay: true,
			autoPlaySpeed: 500,
			speed: 500,
			vertical: true,
			asNavFor: '.map-carousel-slick',
		});

		$("body").on('click', ".start-scroll", function() {
			guideSliderSlick.slick('slickNext');
		})

	}

});

