/* Button Component JS File */
$(document).ready(function() {

	/* ==============================================================
		Video Popup Button
	============================================================== */
	$(document).on('click', '.video-popup-button-trigger', function() {
		const video = $(this).attr('data-video');
		if (typeof video !== "undefined") {
			$.magnificPopup.close();
			$.magnificPopup.open({
				type: 'ajax',
				items: {},
				mainClass: 'video-popup fade-in',
				tLoading: coreData.loader,
				ajax: {
					settings: {
						type: "post",
						url: coreData.ajaxurl,
						data: {
							action: "videoPopup",
							video: video
						},
					},
					cursor: 'mfp-ajax-cur',
					tError: '<a href="%url%">The content</a> could not be loaded.'
				},
				callbacks: {
					ajaxContentAdded: function() {

					}
				}
			});
		}
	});

	/* ==============================================================
		Form Popup Button
	============================================================== */
	$(document).on('click', '.form-popup-trigger', function() {
		const form = $(this).attr('data-form');
		const title = $(this).attr('data-title');
		const content = $(this).attr('data-content');
		if (typeof form !== "undefined") {
			formPopup({
				form: form,
				title: title,
				content: content
			})
		}
	});

	/* ==============================================================
		Guide Popup Button
	============================================================== */
	$(document).on('click', '.guide-popup-trigger', function(e) {
		const guide = $(this).attr('data-guide');
		if (typeof guide !== "undefined") {
			guidePopup(e, $this);
		}
	});

	/* ==============================================================
		Contact Popup Button
	============================================================== */
	$(document).on('click', '.contact-popup-trigger', function() {
		const form = $(this).attr('data-form');
		const title = $(this).attr('data-title');
		const content = $(this).attr('data-content');
		if (typeof form !== "undefined") {
			contactPopup({
				form: form,
				title: title,
				content: content
			})
		}
	});

	/* ==============================================================
		Link Popup Button
	============================================================== */
	$(document).on('click', '.link-popup-trigger', function() {
		const link = $(this).attr('data-link');
		if (typeof link !== "undefined") {
			linkPopup({
				link : link
			})
		}
	});
});
