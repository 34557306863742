/* Banner Home Component JS File */
import slick from 'slick-carousel';
import ScrollMagic from 'ScrollMagic';
import 'animation.gsap';
import 'debug.addIndicators';
import TimelineMax from 'TimelineMax';

$(document).ready(function() {
	if ($(".banner-home-wrapper").length) {
		// $(window).on('load scroll', function() {
		// 	if ($(window).width() >= 992) {
		// 		const top_of_bhw = $(".banner-home-wrapper").offset().top;
		// 		const height_of_bhw =  $(".banner-home-wrapper").outerHeight();
		// 		if ($(window).scrollTop() > (top_of_bhw - (height_of_bhw / 10)) &&
		// 			$(window).scrollTop() < (top_of_bhw + height_of_bhw)) {
		// 			$(".banner-home-wrapper").addClass('active');
		// 		}
		// 		else { $(".banner-home-wrapper").removeClass('active'); }
		// 	}
		// });

		$(".banner-home-slick").slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: true,
			autoplaySpeed: 2000,
			speed: 1000,
			mobileFirst: true,
			pauseOnDotsHover: false,
			pauseOnFocus: false,
			pauseOnHover: true,
			arrows: false,
			dots: true,
			fade: true,
			rows: 0,
			appendDots: '.banner-slide-navigation',
			customPaging: function(slider, i) {
				return '<button><span>' + ('0' + (i + 1)).slice(-2) + '</span></button>';
			}
		});

		const bannerHomeController = new ScrollMagic.Controller();
		const bannerHomeScene = new ScrollMagic.Scene({
			triggerHook: "onEnter",
			duration: "200%"
		})
		.setTween(".banner-home-wrapper .banner-background .background", {
			y: "40%"
		});
		bannerHomeScene.addTo(bannerHomeController);

		if ($(".video-play-button").length > 0) {
			if ($(window).width() >= 1200) {
				const video = $(".video-play-button").attr('data-video');
				const homeBannerVideo = jwplayer('home-banner-video');
				$.post(coreData.ajaxurl, {
					'action': 'ajax_get_video_manifest',
					'video': video
				}, function(manifest) {
					homeBannerVideo.setup({
						file: $.parseJSON(manifest),
						autostart: false,
						controls: true,
						mute: false,
						repeat: false,
						// stretching: 'fill',
						width: '100%',
						height: '100%'
					});

					homeBannerVideo.on('ready', function() {
						if ($(".banner-home-wrapper .banner-slide").hasClass('video-playing')) {
							homeBannerVideo.play();
						}
					});
				});

				$(document).on('click', '.banner-home-wrapper .video-play-button', function () {
					const $this = $(this);
					if (!$(".banner-home-wrapper .banner-slide").hasClass('video-playing')) {
						$(".banner-home-wrapper .banner-slide").addClass('video-playing');
						let resetVideoBanner;
						homeBannerVideo.play();
						homeBannerVideo.on('play', function() {

						}).on('pause', function() {
							resetVideoBanner = setTimeout(function() {
								$(".banner-home-wrapper .banner-slide").removeClass('video-playing');
							}, 2500);
						}).on('complete', function() {
							$("body").removeClass('video-playing');
							if (typeof $this.attr('data-form') !== "undefined") {
								videoCompleteFormPopup({
									video: $this.attr('data-video'),
									form: $this.attr('data-form'),
									title: $this.attr('data-form-title'),
									content: $this.attr('data-form-content')
								});
							}
						});
					}
				})
			} else {
				$(document).on('click', '.banner-home-wrapper .video-play-button', function(e) {
					e.preventDefault();
					videoPopup(e, $(this));
				})
			}
		}

		// $(document).on('click', '.video-play-button', function(e) {
		// 	e.preventDefault();
		// 	videoPopup(e, $(this));
		// })
	}
});

