/* Parent Boxes Block JS File */
import ScrollMagic from 'ScrollMagic';
import 'animation.gsap';
import 'debug.addIndicators';
import TimelineMax from 'TimelineMax';
import 'jquery-sticky-kit';
import ResizeSensor from 'css-element-queries/src/ResizeSensor';

$(document).ready(function() {
	if ($('.parent-boxes-wrapper').length > 0) {

		$(document).on('click', '.parent-boxes-wrapper .parent-boxes-trigger', function(e) {
			e.preventDefault();
			const parent = $(this).attr('data-parent');
			const slug = $(this).attr('data-slug');
			const this_ID = '.parent-boxes-wrapper #' + $(this).attr('id');
			const url = '/' + parent + '/' + slug + '/';
			$(".parent-boxes-wrapper .parent-boxes-trigger").removeClass('active');
			$(this_ID).addClass('active');
			$(".parent-boxes-wrapper .tab-pane").removeClass('active show');
			$(".parent-boxes-wrapper .tab-pane#" + slug).tab('show');

			if ($('.banner-parent .banner-parent-content .banner-slide .banner-content .content-inner .body-wrapper').length) {
				$('.banner-parent .banner-parent-content .banner-slide .banner-content .content-inner .body-wrapper .section-body').removeClass('active');
				if ($('.banner-parent .banner-parent-content .banner-slide .banner-content .content-inner .body-wrapper .section-body.extension#' + slug).length) {
					$('.banner-parent .banner-parent-content .banner-slide .banner-content .content-inner .body-wrapper').height($(".banner-parent .banner-parent-content .banner-slide .banner-content .content-inner .body-wrapper .section-body.extension#" + slug).height());
					$('.banner-parent .banner-parent-content .banner-slide .banner-content .content-inner .body-wrapper .section-body.extension#' + slug).addClass('active');
				}
				else {
					$('.banner-parent .banner-parent-content .banner-slide .banner-content .content-inner .body-wrapper').height($(".banner-parent .banner-parent-content .banner-slide .banner-content .content-inner .body-wrapper .section-body.default").height());
					$('.banner-parent .banner-parent-content .banner-slide .banner-content .content-inner .body-wrapper .section-body.default').addClass('active');
				}
			}

			if (window.history.replaceState) {
				window.history.pushState({}, null, url);
			}

			if (!$('.tab-wrapper').hasClass('active')) {
				$('.tab-wrapper').addClass('change');
				setTimeout( function() {
					$('.tab-wrapper').removeClass('change');
					$('.tab-wrapper').addClass('active');
					const halfbackgroundBottom = $('.parent-boxes-wrapper #section-background-bottom').height() / 2;
					$('#section-background-top').css('height', 'calc(100% + ' + halfbackgroundBottom + 'px)');

					if ($('.parent-boxes-wrapper .tab-wrapper.active .tab-content .tab-pane.active .parent-button-wrapper').length > 0) {
						if ($(window).width() >= 992) {
							$('.parent-boxes-wrapper .parent-button-wrapper .parent-button').trigger("sticky_kit:detach");
							parent_button_aside('.parent-boxes-wrapper .tab-wrapper.active .tab-content .tab-pane.active .parent-button-wrapper');
						}
					}
				}, 500);
			}
			else {
				const halfbackgroundBottom = $('.parent-boxes-wrapper #section-background-bottom').height() / 2;
				$('#section-background-top').css('height', 'calc(100% + ' + halfbackgroundBottom + 'px)');

				if ($('.parent-boxes-wrapper .tab-wrapper.active .tab-content .tab-pane.active .parent-button-wrapper').length > 0) {
					if ($(window).width() >= 992) {
						$('.parent-boxes-wrapper .parent-button-wrapper .parent-button').trigger("sticky_kit:detach");
						parent_button_aside('.parent-boxes-wrapper .tab-wrapper.active .tab-content .tab-pane.active .parent-button-wrapper');
					}
				}
			}

			// $('html, body').animate({
				// scrollTop: $('.content-with-sidebar-1').offset().top - 50
			// }, 500);

		});


		$(window).on('load rotate resize', function() {
			const halfbackgroundBottom = $('.parent-boxes-wrapper #section-background-bottom').height() / 2;
			$('.parent-boxes-wrapper #section-background-top').css('height', 'calc(100% + ' + halfbackgroundBottom + 'px)');
			$('.parent-boxes-wrapper #section-background-left-right').addClass('active');
			// if (!$('.parent-boxes-wrapper .tab-parent').hasClass('active')) {
				// const postContent = $('.parent-boxes-wrapper .content-wrapper .tab-wrapper .parent-post-wrapper .parent-post-body .parent-post-body-wrapper .parent-post-content-wrapper');
				// if ($(window).width() >= 768) { matchHeight(postContent); }
				// else { postContent.height('auto'); }
			// }
			if ($(window).width() >= 992) {
				parent_button_aside('.parent-boxes-wrapper .tab-wrapper.active .tab-content .tab-pane.active .parent-button-wrapper');
			}
			else if ($(window).width() < 992) {
				$('.parent-boxes-wrapper .parent-button-wrapper .parent-button').trigger("sticky_kit:detach");
			}
		});
	}

	if ($('.parent-boxes-wrapper .tab-wrapper.active .tab-content .tab-pane.active .parent-button-wrapper').length > 0) {
		new ResizeSensor($('.parent-boxes-wrapper'), function() {
			$('.parent-boxes-wrapper .parent-button-wrapper .parent-button').trigger("sticky_kit:recalc");
		});
		if ($(window).width() >= 992) {
			parent_button_aside('.parent-boxes-wrapper .tab-wrapper.active .tab-content .tab-pane.active .parent-button-wrapper');
		}
	}
});


window.parent_button_aside = function parent_button_aside(element) {
	// $(".parent-boxes-wrapper .parent-button-wrapper").each(function() { #' + $(this).attr('id') });
	const options = {
		parent: $(element),
		offset_top: 300,
		inner_scrolling: true
	};
	const sticky = $(element + ' .parent-button').stick_in_parent(options);
	sticky.on("sticky_kit:bottom", function(e) {
		$(element + ' .parent-button').addClass('at-bottom');
	}).on("sticky_kit:unbottom", function(e) {
		$(element + ' .parent-button').removeClass('at-bottom');
	});
}
